/**
 * helper functions
 */
export function removeClass(el, className) {
    if (el.classList) el.classList.remove(className);
    else
      el.className = el.className.replace(
        new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"),
        " "
      );
  }
  export function addClass(el, className) {


    if (el.classList) el.classList.add(className);
    else el.className += " " + className;
  }
  export function toggleClass(el, className) {
    if (el.classList) {
      el.classList.toggle(className);
    } else {
      var classes = el.className.split(" ");
      var existingIndex = -1;
      for (var i = classes.length; i--; ) {
        if (classes[i] === className) existingIndex = i;
      }
      if (existingIndex >= 0) classes.splice(existingIndex, 1);
      else classes.push(className);
      el.className = classes.join(" ");
    }
  }
  export function hasClass(el, className) {
    if (el.classList) {
      if (el.classList.contains(className)) {
        return true;
      } else {
        return false;
      }
    } else {
      if (new RegExp("(^| )" + className + "( |$)", "gi").test(el.className)) {
        return true;
      } else {
        return false;
      }
    }
  }
  