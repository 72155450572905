import * as firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import { exchangeRate } from "./exchangeRate";
import { removeClass } from "./helperFunctions";

export async function sumTotals(username, category) {
        const snapshot =  await firebase.firestore().collection(username).doc(category).collection("transactions").get();
        let valuesMap = snapshot.docs.map(doc => doc.data());
        let total = 0;
        for (let value of valuesMap) {
            total = total + value.USD;
        }
        updateTotal(total, username, category);
}

async function updateTotal(newTotal, username, category) {
    var db = firebase.firestore();
    var balanceRef = db.collection(username).doc("balances");
    balanceRef.update({
        [category]: newTotal.toFixed(2)
    })
}