import moment from 'moment';

export async function exchangeRate(date) {
    if (date) { 
        var todayDate = moment(date.toString()).subtract(1, 'days') 
    } else { 
        var todayDate = moment();
    };

    let todayString = moment(todayDate).format("YYYY-MM-DD");
    let yesterdayString = moment(todayDate).subtract(2, 'days').format("YYYY-MM-DD");

    const url =  `https://api.frankfurter.app/${yesterdayString}..${todayString}?from=USD&to=TRY`

    let response = await fetch(url);
    let data = await response.json();
    let rate = 0;
    
    if (data?.rates[todayString]?.TRY) {
        rate = data.rates[todayString][Object.keys(data.rates[todayString])]
    } else {
        const latestURL = `https://api.frankfurter.app/latest?from=USD&to=TRY`
        let latestResponse = await fetch(latestURL);
        let latestData = await latestResponse.json();
        rate = latestData.rates[Object.keys(latestData.rates)[0]]

    }
    
    return rate

}