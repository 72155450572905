
import * as firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import { exchangeRate } from "./exchangeRate";
import { removeClass } from "./helperFunctions";
import { sumTotals } from "./sumTotals";

export async function loadBalances(username) {

    var db = firebase.firestore();
    var date = new Date();
    let dailyExchangeRate = await exchangeRate(date);

    var docRef = db.collection(username);
    docRef.doc("balances").onSnapshot((doc) => {
        if (doc.exists) {

            var spendingBalance = doc.data().spending;
            var savingsBalance = doc.data().savings;
            var givingBalance = doc.data().giving;

            updateBalance(username, 'spending', spendingBalance, dailyExchangeRate);
            updateBalance(username, 'saving', savingsBalance, dailyExchangeRate);
            updateBalance(username, 'giving', givingBalance, dailyExchangeRate);
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    })
}


async function updateBalance (username, category, dollarAmount, dailyExchangeRate) {
    let balanceContainer = document.querySelector(`#${username} .${category}`);
    let dollarBalance = balanceContainer.querySelector('.amount.USD');
    let TLBalance = balanceContainer.querySelector('.amount.TL');
    
    let TLAmount =  dollarAmount * dailyExchangeRate;
    dollarBalance.innerText = dollarAmount;
    TLBalance.innerText = Math.round((TLAmount + Number.EPSILON) * 100) / 100;
    
}


export async function loadTransactions(username, accountType) {
    var db = firebase.firestore();
    var docRef = db.collection(username).doc(accountType);
    clearTransactionTable(username);

    const snapshot =  await docRef.collection("transactions").orderBy('date', 'desc').get();
    let valuesMap = snapshot.docs.map(doc => doc.data());
    for (let value of valuesMap) {
        createTransactionRow(value, username)
    }
    removeClass(document.querySelector(`#${username} .transaction_table`), 'hide');
}


function createTransactionRow(data, username) {
    var description = data.description;
    var amountUSD = data.USD;
    var amountTL = data.TL;
    var date = data.date
    var row = `<tr>
        <td>${date}</td>
        <td>${description}</td>
        <td>$${amountUSD}${amountTL ? ' / ' + amountTL + 'TL' : ''}</td>
    </tr>`

    var table = document.querySelector(`#${username} .transaction_table tbody`);    
    table.insertAdjacentHTML('beforeend', row);

    return row;
}

function clearTransactionTable(username) {
    var tableHeaderRowCount = 1;
    var table = document.querySelector(`#${username} .transaction_table`);
    var rowCount = table.rows.length;
    for (var i = tableHeaderRowCount; i < rowCount; i++) {
        table.deleteRow(tableHeaderRowCount);
    }
}

export async function addWeeklyAllowance(username) {
    var db = firebase.firestore();
    var collectionRef = db.collection(username)

    const allowance = await calculateWeeklyAllowance(username);

    collectionRef.doc("balances").update({
        giving: firebase.firestore.FieldValue.increment(allowance.giving),
        savings: firebase.firestore.FieldValue.increment(allowance.savings),
        spending: firebase.firestore.FieldValue.increment(allowance.spending)
    })
}

async function calculateWeeklyAllowance(username) {
    let db = firebase.firestore();
    let collectionRef = db.collection(username)

    let infoRef = await  collectionRef.doc('info').get();
        let birthdate = infoRef.data().DOB.toDate()
        let year = new Date(birthdate).getFullYear();
        let month = new Date(birthdate).getMonth();
        let date = new Date(birthdate).getDate();
        let age = await getAgeFromBirthDate(year, month, date);

        let totalAllowance = age*.5;

        
        let allowance = {
            total: Math.round((totalAllowance * 22 + Number.EPSILON) * 100) / 100,
            giving: Math.round(((totalAllowance*.1*22) + Number.EPSILON) * 100) / 100 ,
            savings: Math.round(((totalAllowance*.5*22) + Number.EPSILON) * 100) / 100 ,
            spending: Math.round(((totalAllowance*.4*22) + Number.EPSILON) * 100) / 100 ,
        }
        return allowance;
}

const getAgeFromBirthDate = (year, month, day) => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - year);
    date.setMonth(date.getMonth() - month);
    date.setDate(date.getDate() - day);
    return date.getFullYear();
 };
 
 export async function newTransactionForm(username) {
    const newExpense = `<form class="expenseInput_container">
    <h2>New Transaction:</h2>
    <label for="transactionDate_${username}">Date:
    <input name="transactionDate_${username}" id="transactionDate_${username}" type="date"/>
    </label>
    <label for="expenseType_${username}">
        Expense Category:
        <select id="expenseType_${username}" name="expenseType_${username}">
            <option value="spending">Spending</option>
            <option value="savings">Savings</option>
            <option value="giving">Giving</option>
        </select>
    </label>
    <label for="TLamount_${username}">
        Amount in Turkish Lira:
        <input name="TLamount_${username}" id="TLamount_${username}" type="number"/>
    </label>
    <label for="USDamount_${username}">
        Amount in USD:
        <input name="USDamount_${username}" id="USDamount_${username}" type="number"/>
    </label>
    <label for="description_${username}">
        Description:
        <input name="description_${username}" id="description_${username}" />
    </label>
    <button name="submitExpense_${username}" id="submitExpense_${username}">Submit Expense</button>
</form>`

    document.querySelector(`#${username}`).insertAdjacentHTML('beforeend', newExpense);

    document.querySelector(`#submitExpense_${username}`).addEventListener("click", (e) => {
        submitExpense(e, username);
    });

    document.querySelector(`#TLamount_${username}`).addEventListener('change', async () => {
        if (document.querySelector(`#transactionDate_${username}`).value) {
            var date = new Date(document.querySelector(`#transactionDate_${username}`).value);
        } else {
            var date = new Date();
        }

        let rate = await exchangeRate(date);
        let TLvalue = document.querySelector(`#TLamount_${username}`).value;
        convertTLtoUSD(TLvalue, rate, username);
    });
}

function convertTLtoUSD(TL, rate, username) {
    let USDvalue = TL/rate;
    USDvalue = Math.round((USDvalue + Number.EPSILON) * 100) / 100
    console.log(USDvalue);
    document.querySelector(`#USDamount_${username}`).value = USDvalue;
}
export function submitExpense(e, username) {
    e.preventDefault();
    let date = document.querySelector(`#transactionDate_${username}`).value;
    let category = document.querySelector(`#expenseType_${username}`).value;
    let TLAmount = parseFloat(document.querySelector(`#TLamount_${username}`).value);
    let USDAmount = parseFloat(document.querySelector(`#USDamount_${username}`).value);
    let description = document.querySelector(`#description_${username}`).value

    let expenseData = {
        date: date,
        USD: USDAmount,
        description: description
    };

    if (TLAmount) {
        expenseData.TL = TLAmount
    }
    var db = firebase.firestore();
    var expenseRef = db.collection(username).doc(category).collection("transactions");
    expenseRef.add(expenseData).then(function() {
        console.log("done");
        document.querySelector(`#${username} .expenseInput_container`).reset();
        sumTotals(username, category);
    });
}