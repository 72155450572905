import * as firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebaseui"
import {
    toggleClass,
    hasClass,
    addClass,
    removeClass
  } from "./modules/helperFunctions";
import { loadBalances, loadTransactions, addWeeklyAllowance, submitExpense, newTransactionForm } from "./modules/loadData";
import { sumTotals } from "./modules/sumTotals";

// Your web app's Firebase configuration
var firebaseConfig = {
apiKey: "AIzaSyB39aXzf1BEZ9g6Cu5dl9KEuV_iAvWjU6w",
authDomain: "allowance-tracker-mills.firebaseapp.com",
databaseURL: "https://allowance-tracker-mills.firebaseio.com",
projectId: "allowance-tracker-mills",
storageBucket: "allowance-tracker-mills.appspot.com",
messagingSenderId: "674862977813",
appId: "1:674862977813:web:123fc2ca9bdaa735854f1c"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
  

  firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
      console.log("logged In");
      var displayName = user.displayName;
      if (displayName === 'micah') {
        let categories = ['spending', 'savings', 'giving'];
        let kids = ['abbie', 'caleb', 'ellie', 'hannah'];
          let wrappers = document.querySelectorAll('.wrapper.hide');
          [...wrappers].forEach((wrapper) => {
              removeClass(wrapper, 'hide');
          })

          kids.forEach((kid) => {
              loadBalances(kid);
              newTransactionForm(kid);
              categories.forEach((category) => {
                sumTotals(kid, category);
              })
            //   addWeeklyAllowance(kid);
          })
      } else {
          let wrapper = document.querySelector(`#${displayName}.wrapper`)
          removeClass(wrapper, 'hide');
          loadBalances(displayName);
      }
      
      let balanceContainers = document.querySelectorAll('.balanceContainer');
    
      balanceContainers.forEach(balanceContainer => balanceContainer.addEventListener('click', event => {

        var username = event.target.closest('.wrapper').id
        if(hasClass(event.target, 'balanceContainer')) {
        var category = event.target.dataset.type;
        } else {
        var category = event.target.parentNode.dataset.type;
        }

        loadTransactions(username, category);
      }));
    
      
    } else {
      console.log("not logged in");
    SignIn();
    }
  });
  
  function SignIn() {
      console.log("signing in");
    var uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: function(authResult, redirectUrl) {
          return true;
        },
        uiShown: function() {
          document.getElementById("loader").style.display = "none";
        }
      },
      //Start it here
      credentialHelper: firebaseui.auth.CredentialHelper.ACCOUNT_CHOOSER_COM,
      //End it here
      signInFlow: "popup",
      credentialHelper: firebaseui.auth.CredentialHelper.NONE,
      signInSuccessUrl: "/",
      signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.EmailAuthProvider.PROVIDER_ID
      ]
    };
  
    var ui = new firebaseui.auth.AuthUI(firebase.auth());
    ui.start("#firebaseui-auth-container", uiConfig);
  }